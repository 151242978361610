.page {
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 20px;
}

.title {
    align-self: end;
}

img{
    max-width: 400px;
    height: auto;
    margin: 20px;
}

.status {
    align-self: start;
}
